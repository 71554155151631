<template>
	<div class="block block--pill">
		<button class="block__button--pill" @click="$emit('buttonClick')">
			<span class="block__image--pill" v-if="image">
				<image-preview :source="image" :width="433" :height="433" alt="block image" />
			</span>
			<div class="block__content">
				<div class="block__text--pill">{{ title }}</div>
				<div v-if="isPrice" class="price-review">
					<Price :currency="user.data.currency" :amount="price.amount" :sale-amount="price.sale_amount_available ? price.sale_amount : 0" />
					<PriceOff v-if="showDiscount" :amount="price.amount" :sale-amount="price.sale_amount_available ? price.sale_amount : 0" />
					<div v-if="isReviews" class="rating" :style="{ '--avg-rating': avgReviews }">
						<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M6.6574 0L8.75579 4.11181L13.3148 4.83688L10.0527 8.10319L10.7719 12.6631L6.6574 10.57L2.5429 12.6631L3.26212 8.10319L0 4.83688L4.559 4.11181L6.6574 0Z"
								fill="#FEC337"
							/>
						</svg>

						<span>{{ avgReviews }}</span>
					</div>
				</div>
				<div v-if="quantity != undefined" class="inventory">
					<span :class="{ 'in-stock': isInStock, 'out-of-stock': !isInStock, 'low-stock': isLowStock }">{{ quantity_text }}</span>
				</div>
			</div>
			<div class="d-none block__icon--pill">
				<svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
					<path
						d="M13.8291 5.68359L19.6462 11.5007L13.8291 17.3178"
						stroke="#171616"
						stroke-width="1.2"
						stroke-miterlimit="10"
						stroke-linecap="square"
					/>
					<path
						d="M3.35449 11.5H18.4832"
						stroke="#171616"
						stroke-width="1.2"
						stroke-miterlimit="10"
						stroke-linecap="square"
						stroke-linejoin="round"
					/>
				</svg>
			</div>
		</button>
	</div>
</template>
<script>
	import Price from '../Price'
	import PriceOff from '../PriceOff'
	import ImagePreview from '../ImagePreview'
	import { PRODUCT_TYPES } from '../utils'

	export default {
		components: {
			Price,
			PriceOff,
			ImagePreview,
		},
		props: {
			title: { type: String, default: 'Button' },
			image: { type: String },
			user: { type: Object },
			price: { type: Object, default: () => {} },
			reviews: { type: Array, default: () => [] },
			productType: { type: String },
			showDiscount: { type: Boolean },
			quantity: { type: Number },
		},
		computed: {
			isPrice() {
				const canShowPrice = this.price !== undefined
				return PRODUCT_TYPES.getLeadGeneratingPages().includes(this.productType) && canShowPrice
			},
			isReviews() {
				return this.isPrice && this.reviews && this.reviews.length > 0
			},
			avgReviews() {
				return this.reviews.length ? (this.reviews.reduce((sum, review) => (sum += review.rating), 0) / this.reviews.length).toFixed(1) : 0.0
			},
			isLowStock() {
				return this.quantity > 0 && this.quantity < 3
			},
			isInStock() {
				return this.quantity === undefined || this.quantity > 0
			},
			quantity_text() {
				if (!this.isInStock) return 'Sold Out'
				else {
					let item_string = 'item'
					if (this.quantity > 1) item_string = 'items'
					return `${this.quantity} ${item_string} left`
				}
			},
		},
	}
</script>
