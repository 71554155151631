<template>
	<div class="block block--callout block--preview">
		<div class="d-none block__image" v-if="image">
			<image-preview :source="image" :width="433" :height="433" alt="block image" />
		</div>
		<div class="block__content">
			<div class="block__image" v-if="image">
				<image-preview :source="image" :width="433" :height="433" alt="block image" />
			</div>
			<div class="block__text">
				<h4 class="block__heading">{{ heading }}</h4>
				<p class="block__subheading">{{ tagline }}</p>
				<div v-if="isPrice" class="price-review">
					<Price :currency="user.data.currency" :amount="price.amount" :sale-amount="price.sale_amount_available ? price.sale_amount : 0" />
					<PriceOff v-if="showDiscount" :amount="price.amount" :sale-amount="price.sale_amount_available ? price.sale_amount : 0" />
					<div v-if="isReviews" class="rating" :style="{ '--avg-rating': avgReviews }">
						<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M6.6574 0L8.75579 4.11181L13.3148 4.83688L10.0527 8.10319L10.7719 12.6631L6.6574 10.57L2.5429 12.6631L3.26212 8.10319L0 4.83688L4.559 4.11181L6.6574 0Z"
								fill="#FEC337"
							/>
						</svg>
						<span>{{ avgReviews }}</span>
					</div>
					<div class="flex-linebreak"></div>
					<div v-if="quantity != undefined" class="d-none inventory">
						<span :class="{ 'in-stock': isInStock, 'out-of-stock': !isInStock, 'low-stock': isLowStock }">{{ quantity_text }}</span>
					</div>
				</div>
				<div v-if="quantity != undefined" class="inventory">
					<span :class="{ 'in-stock': isInStock, 'out-of-stock': !isInStock, 'low-stock': isLowStock }">{{ quantity_text }}</span>
				</div>
			</div>
		</div>
		<div class="block__content--preview">
			<Calendar
				v-if="productType === 'meeting'"
				:preview="true"
				:primary-color="userPrimaryColor"
				:slug="product.meeting.slug"
				:username="user.username"
				:isDemo="isDemo"
				@date-selected="preSelectCalendarDate"
			/>
			<WebinarCalendar
				v-if="productType === 'webinar'"
				:preview="true"
				:primary-color="userPrimaryColor"
				:slug="product.webinar.slug"
				:username="user.username"
				:isDemo="isDemo"
				@time-selected="preSelectWebinarSession"
			/>
			<CoursePreview
				v-if="productType === 'course'"
				:preview="true"
				:primary-color="userPrimaryColor"
				:product="product"
				:username="user.username"
			/>
			<DigitalDownloadPreview
				v-if="productType === 'digital-download'"
				:preview="true"
				:primary-color="userPrimaryColor"
				:product="product"
				:username="user.username"
			/>
		</div>
		<div class="cta-button">
			<button class="cta-button__button" @click="$emit('buttonClick', { preSelectValue: true })">
				<div class="cta-button__label">{{ title }}</div>
				<div class="d-none cta-button__icon--right">
					<svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M19.4524 8.11813L10.6704 0.695421C10.3454 0.420714 9.84766 0.651735 9.84766 1.07729V15.9227C9.84766 16.3483 10.3454 16.5793 10.6704 16.3046L19.4524 8.88187C19.6887 8.68213 19.6887 8.31787 19.4524 8.11813Z"
							fill="white"
						/>
						<rect x="0.796875" y="6.1842" width="10.321" height="4.73679" rx="0.5" fill="white" />
					</svg>
				</div>
			</button>
		</div>
		<div class="cta-button-secondary">
			<button class="cta-button__button" @click="$emit('buttonClick', {})">
				<div class="cta-button__label">Learn More</div>
				<div class="d-none cta-button__icon--right">
					<svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M19.4524 8.11813L10.6704 0.695421C10.3454 0.420714 9.84766 0.651735 9.84766 1.07729V15.9227C9.84766 16.3483 10.3454 16.5793 10.6704 16.3046L19.4524 8.88187C19.6887 8.68213 19.6887 8.31787 19.4524 8.11813Z"
							fill="white"
						/>
						<rect x="0.796875" y="6.1842" width="10.321" height="4.73679" rx="0.5" fill="white" />
					</svg>
				</div>
			</button>
		</div>
	</div>
</template>
<script>
	import Price from '../Price'
	import PriceOff from '../PriceOff'
	import ImagePreview from '../ImagePreview'
	import Calendar from '../Calendar.vue'
	import WebinarCalendar from '../WebinarCalendar.vue'
	import CoursePreview from '../CoursePreview.vue'
	import DigitalDownloadPreview from '../DigitalDownloadPreview.vue'
	import { PRODUCT_TYPES } from '../utils'
	export default {
		components: {
			Price,
			PriceOff,
			ImagePreview,
			Calendar,
			WebinarCalendar,
			CoursePreview,
			DigitalDownloadPreview,
		},
		props: {
			title: { type: String, default: 'Button' },
			heading: { type: String, default: 'Heading' },
			tagline: { type: String, default: 'Subheading text goes here and here subheading text goes here and here.' },
			image: { type: String, default: 'https://assets.stanwith.me/graphics/no__image.svg' },
			user: { type: Object },
			price: { type: Object, default: () => {} },
			reviews: { type: Array, default: () => [] },
			productType: { type: String },
			showDiscount: { type: Boolean },
			quantity: { type: Number },
			pageId: { type: Number },
			product: { type: Object },
			userPrimaryColor: { type: String },
			isDemo: { type: Boolean, default: false },
		},
		computed: {
			isPrice() {
				const canShowPrice = this.price !== undefined
				return PRODUCT_TYPES.getLeadGeneratingPages().includes(this.productType) && canShowPrice
			},
			isReviews() {
				return this.isPrice && this.reviews && this.reviews.length > 0
			},
			avgReviews() {
				return this.reviews.length ? (this.reviews.reduce((sum, review) => (sum += review.rating), 0) / this.reviews.length).toFixed(1) : 0.0
			},
			isLowStock() {
				return this.quantity > 0 && this.quantity < 3
			},
			isInStock() {
				return this.quantity === undefined || this.quantity > 0
			},
			quantity_text() {
				if (!this.isInStock) return 'Sold Out'
				else {
					let item_string = 'item'
					if (this.quantity > 1) item_string = 'items'
					return `${this.quantity} ${item_string} left`
				}
			},
		},
		methods: {
			preSelectCalendarDate(value) {
				this.$emit('calendar-date-selected', { pageId: this.pageId, date: value })
				this.$emit('buttonClick', { preSelectValue: true })
			},
			preSelectWebinarSession(value) {
				this.$emit('webinar-session-selected', { pageId: this.pageId, session: value })
				this.$emit('buttonClick', { preSelectValue: true })
			},
		},
	}
</script>
<style lang="scss">
	.cta-button-secondary {
		.cta-button__button {
			width: 100%;
			display: inline-block;
			text-align: center;
			min-height: 60px;

			.cta-button__label {
				font-family: 'Inter';
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: normal;
				color: var(--stan-store-theme-primary-color);
				width: 80%;
				margin: 0 auto;
			}
		}
	}
</style>
