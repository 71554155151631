<template>
	<div class="block block--lead-magnet">
		<div v-if="image" class="d-none block__image">
			<image-preview :source="image" :width="433" :height="433" alt="block image" />
		</div>
		<div class="block__content">
			<div v-if="image" class="block__image">
				<image-preview :source="image" :width="433" :height="433" alt="block image" />
			</div>
			<div class="block__text">
				<h4 class="block__heading">{{ heading }}</h4>
				<p class="block__subheading">{{ tagline }}</p>
			</div>
		</div>
		<Form
			ref="inputForm"
			:show-base="true"
			:show-message="false"
			:questions="questions"
			:fcpComplete="fcpComplete"
			:disabled="disabled"
			@setFanDetails="setFanDetails"
			@setAnswers="setAnswers"
			@formIsValid="setFanDetailsValid"
		/>
		<div class="cta-button">
			<button class="cta-button__button" :disabled="formSubmitting" @click="handleButtonClick">
				<div class="cta-button__label">{{ title }}</div>
				<div class="d-none cta-button__icon--right">
					<svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M19.4524 8.11813L10.6704 0.695421C10.3454 0.420714 9.84766 0.651735 9.84766 1.07729V15.9227C9.84766 16.3483 10.3454 16.5793 10.6704 16.3046L19.4524 8.88187C19.6887 8.68213 19.6887 8.31787 19.4524 8.11813Z"
							fill="white"
						/>
						<rect x="0.796875" y="6.1842" width="10.321" height="4.73679" rx="0.5" fill="white" />
					</svg>
				</div>
			</button>
		</div>
		<p class="text-center form-error">
			<small class="stan-text-danger text-danger">
				{{ formErrorMessage }}
			</small>
		</p>
	</div>
</template>
<script>
	import Form from './../Form'
	import ImagePreview from '../ImagePreview'

	export default {
		name: 'LeadMagnetButton',
		components: {
			Form,
			ImagePreview,
		},
		props: {
			disabled: { type: Boolean, default: false },
			title: { type: String, default: 'Button' },
			heading: { type: String, default: 'Heading' },
			tagline: { type: String, default: 'Subheading text goes here and here subheading text goes here and here.' },
			image: { type: String, default: 'https://assets.stanwith.me/graphics/no__image.svg' },
			user: {
				type: Object,
				default() {
					return {}
				},
			},
			questions: {
				type: Array,
				default() {
					return []
				},
			},
			fcpComplete: {
				type: Boolean,
				default() {
					return false
				},
			},
		},
		data() {
			return {
				formData: {
					email: '',
					name: '',
					phone_number: '',
					answers: Array(this.questions.length).fill(''),
				},
				isFormValid: false,
				formErrorMessage: '',
				formSubmitting: false,
			}
		},
		created() {},
		methods: {
			setFanDetails(fanDetails) {
				this.formData = fanDetails
				this.formErrorMessage = ''
			},
			setAnswers(answers) {
				this.formData.answers = answers
				this.formErrorMessage = ''
			},
			setFanDetailsValid(formIsValid) {
				this.isFormValid = formIsValid
				this.formErrorMessage = ''
			},
			handleButtonClick() {
				if (this.$refs.inputForm.isFormValid()) {
					this.formSubmitting = true

					const oldCachedAnswers = this.$cookies.get('autofillQuestionAnswers') ?? {}
					const cachedQuestionAnswers = {}
					this.questions.forEach((question, index) => {
						cachedQuestionAnswers[question.content] = this.formData.answers[index]
					})
					let autofillQuestionAnswers = {}
					if (oldCachedAnswers) {
						const country_code = cachedQuestionAnswers['Phone Number']
							? this.formData.phone_number.replace(cachedQuestionAnswers['Phone Number'], '')
							: ''
						autofillQuestionAnswers = {
							...oldCachedAnswers,
							...cachedQuestionAnswers,
							...{
								name: this.formData.name,
								email: this.formData.email,
								countryCode: country_code,
							},
						}
					}
					this.$cookies.set('autofillQuestionAnswers', autofillQuestionAnswers, {
						path: '/',
						maxAge: 60 * 60 * 24 * 365,
					})

					this.$emit('buttonClick', this.formData)
				} else {
					this.formSubmitting = false
					this.formErrorMessage = 'Please complete the full form!'
				}
			},
		},
	}
</script>
<style lang="scss" scoped>
	.default-cursor {
		button:not(:disabled),
		[type='button']:not(:disabled),
		[type='reset']:not(:disabled),
		[type='submit']:not(:disabled) {
			cursor: default;
		}
	}

	.questions-form::v-deep {
		input {
			border-radius: 64px;
			padding: 10px;
			font-size: 14px;
			display: inline-block;
			flex: 1;
			width: 100%;
			max-width: 100%;
			max-height: 100%;
			outline: none;
			background: transparent;
			box-sizing: border-box;
			border: 1px solid rgba(41, 42, 51, 0.2);
			position: relative;
		}
	}
	.form-error {
		margin-bottom: 0;
	}
	.text-danger {
		color: red;
	}
</style>
