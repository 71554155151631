export * from './bookings'
export * from './payments'

export const PAGE_TYPES = {
	DIFITAL_DOWNLOAD: 'digital-download',
	MEETING: 'meeting',
	LEAD_MAGNET: 'lead-magnet',
	THANK_YOU: 'thank-you',
	FULFILLMENT: 'fulfillment',
	MEMBERSHIP: 'membership',
	LINK: 'link',
	STAN_REFERRAL_LINK: 'stan-referral-link',
	COURSE: 'course',
	WEBINAR: 'webinar',
}
